<template>
  <div class="quick-more-actions-modal" v-touch:swipe.bottom="closeModal">
    <div class="heading quick-actions-heading">
      <div>More options for {{ personName }}</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>

    <div class="quick-action" key="view-tree" v-if="showViewTree" @click="viewTree">
      <eye-icon></eye-icon>
      <div>View Tree</div>
    </div>
    <div class="quick-action" key="view-lineage" v-if="showViewLineage" @click="viewLineage">
      <lineage-icon></lineage-icon>
      <div>View Lineage</div>
    </div>
    <div class="quick-action" key="view-relationship" @click="viewRelationship">
      <view-relationship-icon></view-relationship-icon>
      <div>View Relationship</div>
    </div>
    <div class="quick-action" key="delete" v-if="removable" @click="clickDelete">
      <delete-icon></delete-icon>
      <div>Delete</div>
    </div>
  </div>
</template>

<script>
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import CloseIcon from 'vue-material-design-icons/Close';
import DeleteIcon from 'vue-material-design-icons/Delete';
import LineageIcon from 'vue-material-design-icons/FormatListBulletedSquare.vue';
import EyeIcon from 'vue-material-design-icons/Sitemap';
import ViewRelationshipIcon from 'vue-material-design-icons/TransitConnectionVariant';

export default {
  props: {
    personName: String,
    showViewTree: Boolean,
    showViewLineage: Boolean,
    removable: Boolean,
    viewTreeHandler: Function,
    viewRelationshipHandler: Function,
    viewLineageHandler: Function,
    deleteHandler: Function,
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    viewTree() {
      this.trackClick('View Tree');
      this.viewTreeHandler();
    },
    viewLineage() {
      this.trackClick('View Lineage');
      this.viewLineageHandler();
    },
    viewRelationship() {
      this.trackClick('View Relationship');
      this.viewRelationshipHandler();
      this.closeModal();
    },
    clickDelete() {
      this.closeModal();
      this.trackClick('Delete');
      this.deleteHandler();
    },
    trackClick(label) {
      AnalyticsAmplitudeHandler.trackTreeQuickMenuActionClickEvent(label, null);
    },
  },
  components: {LineageIcon, CloseIcon, EyeIcon, DeleteIcon, ViewRelationshipIcon},
  name: 'QuickMoreActionsModal',
};
</script>

<style scoped lang="scss">
.quick-more-actions-modal {
}
</style>
