<template>
  <div class="onboarding-father">
    <div class="main-heading heading-4 heading-5-mobile">Tell us about your father</div>
    <div class="name-field">
      <div class="first-name-field">
        <div class="input-label">First Name</div>
        <input :value="firstName" class="input" ref="nameinput" @input="changeFirstName" @keyup.enter="submit" />
      </div>
      <div class="surname-field">
        <div class="input-label">Last Name</div>
        <input :value="surname" class="input" @input="changeSurname" @keyup.enter="submit" />
      </div>
    </div>
    <div class="year-field">
      <div class="input-label">Birth Year</div>
      <input
        :value="birthYear"
        type="text"
        maxlength="4"
        size="4"
        class="input"
        @input="changeBirthYear"
        @keyup.enter="submit"
      />
    </div>
    <div class="field place-field">
      <div class="input-label">Birth Place</div>
      <family-tree-location-multiselect-container
        placeholder=""
        multiselect-classes="bordered"
        :always-show-remove="false"
        :location="birthLocation || {}"
        :family-tree-id="null"
        @clear="clearBirthLocation"
        @select="changeBirthLocation"
      ></family-tree-location-multiselect-container>
      <div class="helper-text text-sm">Adding a city will improve records matching.</div>
    </div>
    <div class="place-suggestions">
      <div class="chip" v-for="item in suggestions" :key="item.place_id" @click="birthSuggestionClick(item)">
        <div>{{ item.display_text }}</div>
        <plus-icon class="plus-icon" :size="16"></plus-icon>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import uniqBy from 'lodash/uniqBy';
import PlusIcon from 'vue-material-design-icons/Plus';

import FamilyTreeLocationMultiselectContainer from '@/components/common/FamilyTreeLocationMultiselectContainer';

export default {
  mounted() {
    this.$refs.nameinput.focus();
    this.$emit('form-change', this.hasData);
  },
  data() {
    const data = this.$store.getters.treeOnboardingDataState.father || {};
    return {
      firstName: data.first_names && data.first_names[0] ? data.first_names[0].value : '',
      surname: data.surnames && data.surnames[0] ? data.surnames[0].value : '',
      birthYear: data.birth_date ? data.birth_date.year : null,
      birthLocation: data.birth_location,
    };
  },
  computed: {
    suggestions() {
      const {user_country, me, mother, china_id} = this.$store.getters.treeOnboardingDataState;
      const meBirthPlace = me.birth_location;
      const motherBirthPlace = mother ? mother.birth_location : null;
      const china = {display_text: 'China', place_id: china_id, level: 0};
      const suggestions = [meBirthPlace, motherBirthPlace, user_country, china];
      return uniqBy(
        suggestions.filter(item => item && item.display_text),
        'display_text'
      );
    },
    hasData() {
      return this.firstName || this.surname || this.birthYear || this.birthLocation;
    },
  },
  methods: {
    birthSuggestionClick(location) {
      AnalyticsAmplitudeHandler.trackOnboardingClickPlaceShortcutEvent(
        location.level,
        location.display_text,
        location.place_id
      );
      this.changeBirthLocation(location);
    },
    changeFirstName(event) {
      this.firstName = event.target.value;
      this.$emit('form-change', this.hasData);
    },
    changeSurname(event) {
      this.surname = event.target.value;
      this.$emit('form-change', this.hasData);
    },
    changeBirthYear(event) {
      this.birthYear = event.target.value;
      this.$emit('form-change', this.hasData);
    },
    clearBirthLocation() {
      this.changeBirthLocation(null);
    },
    changeBirthLocation(location) {
      this.birthLocation = location;
      this.$emit('form-change', this.hasData);
    },
    getData() {
      const father = this.$store.getters.treeOnboardingDataState.father || {};
      const birthDate = father.birth_date || {};
      const firstName = {value: this.firstName, type: ''};
      const surname = {value: this.surname, type: ''};
      return {
        first_names: firstName.value ? [firstName] : [],
        surnames: surname.value ? [surname] : [],
        birth_date: {year: parseInt(this.birthYear) || null, month: birthDate.month, day: birthDate.day},
        birth_location: this.birthLocation,
      };
    },
    submit() {
      this.$emit('submit');
    },
  },
  components: {FamilyTreeLocationMultiselectContainer, PlusIcon},
  name: 'OnboardingFather',
};
</script>

<style lang="scss" scoped>
.onboarding-father {
  .main-heading {
    color: $neutral-600;
  }
  .name-field {
    display: flex;
    align-items: center;
    column-gap: 24px;
    margin-top: 24px;
    > * {
      flex-grow: 1;
      input {
        width: 100%;
      }
    }
  }
  .year-field {
    margin-top: 24px;
    input {
      width: 120px;
    }
  }
  .place-field {
    margin-top: 24px;
  }
  .helper-text {
    margin-top: 8px;
    color: $neutral-500;
  }
}
</style>
