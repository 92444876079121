<template>
  <div class="onboarding-start">
    <img :src="$getAsset('/assets/services/family-tree-library-transparent.png')" width="400" height="301" />
    <div class="heading-4">Build your tree, get record matches!</div>
    <mcr-button-router-link :to="nextStepRoute" class="bold text-md main-button" @click="trackStartClick"
      >Start My Journey</mcr-button-router-link
    >
    <div class="gedcom-section">
      <hr class="divider-2" />
      <div class="text">I have a family tree from another platform</div>
      <mcr-button-router-link :to="gedcomImportRoute" class="white bold small"
        ><upload-icon :size="20" /> <span>Import GEDCOM File</span></mcr-button-router-link
      >
    </div>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import UploadIcon from 'vue-material-design-icons/OpenInApp';

export default {
  computed: {
    nextStepRoute() {
      return {name: 'familytree-onboarding-me'};
    },
    gedcomImportRoute() {
      return {name: 'familytree-import-gedcom', query: {onboarding: 'true'}};
    },
  },
  methods: {
    trackStartClick() {
      AnalyticsAmplitudeHandler.trackOnboardingNextStepEvent('Introduction', null);
    },
  },
  name: 'OnboardingStart',
  components: {McrButtonRouterLink, UploadIcon},
};
</script>

<style lang="scss" scoped>
.onboarding-start {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .heading-4 {
    margin: 32px 0;
  }
  .main-button {
    min-width: 165px;
  }
  .gedcom-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 32px;

    hr {
      width: 100%;
    }

    .text {
      margin: 20px 0 12px;
    }
  }
}
</style>
