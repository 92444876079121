<template>
  <div class="onboarding-grandparent">
    <div class="main-heading heading-4 heading-5-mobile">{{ mainTitle }}</div>
    <div class="parent-select">
      <div class="parent-card" :class="{active: selectedParentGender === 'f'}" @click="selectParent('f')">
        <div class="picture-container">
          <person-icon-colored gender="f"></person-icon-colored>
        </div>
        <div>{{ motherFullName }}</div>
      </div>
      <div class="parent-card" :class="{active: selectedParentGender === 'm'}" @click="selectParent('m')">
        <div class="picture-container">
          <person-icon-colored gender="m"></person-icon-colored>
        </div>
        <div>{{ fatherFullName }}</div>
      </div>
    </div>

    <div class="grandparent-form" v-if="selectedParentGender">
      <div class="field">
        <div class="input-label">Gender</div>
        <div class="radio">
          <div v-for="(name, value) in genderOptions">
            <input :id="value" type="radio" name="gender-radio" :value="value" v-model="gender" />
            <label :for="value">{{ name }}</label>
          </div>
        </div>
      </div>

      <div class="name-field">
        <div class="first-name-field">
          <div class="input-label">First Name</div>
          <input :value="firstName" class="input" ref="nameinput" @input="changeFirstName" @keyup.enter="submit" />
        </div>
        <div class="surname-field">
          <div class="input-label">Last Name</div>
          <input :value="surname" class="input" @input="changeSurname" @keyup.enter="submit" />
        </div>
      </div>
      <div class="year-field">
        <div class="input-label">Birth Year</div>
        <input
          :value="birthYear"
          type="text"
          maxlength="4"
          size="4"
          class="input"
          @input="changeBirthYear"
          @keyup.enter="submit"
        />
      </div>
      <div class="field place-field">
        <div class="input-label">Birth Place</div>
        <family-tree-location-multiselect-container
          placeholder=""
          multiselect-classes="bordered"
          :always-show-remove="false"
          :location="birthLocation || {}"
          :family-tree-id="null"
          @clear="clearBirthLocation"
          @select="changeBirthLocation"
        ></family-tree-location-multiselect-container>
        <div class="helper-text text-sm">Adding a city will improve records matching.</div>
      </div>
      <div class="place-suggestions">
        <div class="chip" v-for="item in suggestions" :key="item.place_id" @click="birthSuggestionClick(item)">
          <div>{{ item.display_text }}</div>
          <plus-icon class="plus-icon" :size="16"></plus-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import uniqBy from 'lodash/uniqBy';
import PlusIcon from 'vue-material-design-icons/Plus';
import {mapGetters} from 'vuex';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import FamilyTreeLocationMultiselectContainer from '@/components/common/FamilyTreeLocationMultiselectContainer';

export default {
  mounted() {
    this.$emit('form-change', false);
  },
  data() {
    return {
      selectedParentGender: '',
      gender: 'm',
      firstName: '',
      surname: '',
      birthYear: null,
      birthLocation: null,
    };
  },
  computed: {
    ...mapGetters(['treeOnboardingDataState']),
    mainTitle() {
      if (this.selectedParentGender) {
        const name =
          this.selectedParentGender === 'm' ? this.fatherName || 'Your Father' : this.motherName || 'Your Mother';
        return `Add a parent of ${name}:`;
      }
      return 'Add a parent of:';
    },
    fatherName() {
      const data = this.$store.getters.treeOnboardingDataState.father || {};
      return data.first_names && data.first_names[0] ? data.first_names[0].value || '' : '';
    },
    fatherSurname() {
      const data = this.$store.getters.treeOnboardingDataState.father || {};
      return data.surnames && data.surnames[0] ? data.surnames[0].value || '' : '';
    },
    fatherFullName() {
      return `${this.fatherName} ${this.fatherSurname}`.trim() || 'Your Father';
    },
    motherName() {
      const data = this.$store.getters.treeOnboardingDataState.mother || {};
      return data.first_names && data.first_names[0] ? data.first_names[0].value || '' : '';
    },
    motherSurname() {
      const data = this.$store.getters.treeOnboardingDataState.mother || {};
      return data.surnames && data.surnames[0] ? data.surnames[0].value || '' : '';
    },
    motherFullName() {
      return `${this.motherName} ${this.motherSurname}`.trim() || 'Your Mother';
    },
    genderOptions() {
      let name = this.selectedParentGender === 'm' ? this.fatherName : this.motherName;
      if (!name) {
        name = this.selectedParentGender === 'm' ? 'Your Father' : 'Your Mother';
      }
      return {m: `${name}'s Father`, f: `${name}'s Mother`};
    },
    suggestions() {
      const {user_country, me, mother, father, china_id} = this.$store.getters.treeOnboardingDataState;
      const meBirthPlace = me.birth_location;
      const motherBirthPlace = mother ? mother.birth_location : null;
      const fatherBirthPlace = father ? father.birth_location : null;
      const china = {display_text: 'China', place_id: china_id, level: 0};
      const suggestions = [meBirthPlace, motherBirthPlace, fatherBirthPlace, user_country, china];
      return uniqBy(
        suggestions.filter(item => item && item.display_text),
        'display_text'
      );
    },
    hasData() {
      return this.firstName || this.surname || this.birthYear || this.birthLocation;
    },
  },
  methods: {
    selectParent(gender) {
      this.selectedParentGender = gender;
      this.$nextTick(() => {
        this.$refs.nameinput.focus();
        this.$emit('form-change', this.hasData);
      });
    },
    changeFirstName(event) {
      this.firstName = event.target.value;
      this.$emit('form-change', this.hasData);
    },
    changeSurname(event) {
      this.surname = event.target.value;
      this.$emit('form-change', this.hasData);
    },
    changeBirthYear(event) {
      this.birthYear = event.target.value;
      this.$emit('form-change', this.hasData);
    },
    clearBirthLocation() {
      this.changeBirthLocation(null);
    },
    changeBirthLocation(location) {
      this.birthLocation = location;
      this.$emit('form-change', this.hasData);
    },
    birthSuggestionClick(location) {
      AnalyticsAmplitudeHandler.trackOnboardingClickPlaceShortcutEvent(
        location.level,
        location.display_text,
        location.place_id
      );
      this.changeBirthLocation(location);
    },
    getData() {
      const firstName = {value: this.firstName, type: ''};
      const surname = {value: this.surname, type: ''};
      return {
        parentGender: this.selectedParentGender,
        gender: this.gender,
        first_names: firstName.value ? [firstName] : [],
        surnames: surname.value ? [surname] : [],
        birth_date: {year: parseInt(this.birthYear) || null, month: null, day: null},
        birth_location: this.birthLocation,
      };
    },
    submit() {
      this.$emit('submit');
    },
  },
  components: {PersonIconColored, FamilyTreeLocationMultiselectContainer, PlusIcon},
  name: 'OnboardingGrandparent',
};
</script>

<style lang="scss" scoped>
.onboarding-grandparent {
  .main-heading {
    color: $neutral-600;
  }
  .parent-select {
    display: flex;
    column-gap: 24px;
    margin: 24px 0;

    .parent-card {
      padding: 12px;
      background: white;
      border: 1px solid $neutral-200;
      border-radius: 4px;
      display: flex;
      flex: 1;
      align-items: center;
      column-gap: 12px;
      cursor: pointer;

      .picture-container {
        width: 40px;
        height: 43px;

        .person-icon-colored::v-deep svg {
          width: 30px;
          height: 30px;
        }
      }

      &.active {
        border-color: $primary-400;
      }
    }
  }

  .grandparent-form {
    .radio {
      display: flex;
      flex-wrap: wrap;
      > div {
        margin-top: 4px;
        margin-right: 20px;
        white-space: nowrap;
      }
    }

    .name-field {
      display: flex;
      align-items: center;
      column-gap: 24px;
      margin-top: 20px;
      > * {
        flex-grow: 1;
        input {
          width: 100%;
        }
      }
    }
    .year-field {
      margin-top: 24px;
      input {
        width: 120px;
      }
    }
    .place-field {
      margin-top: 24px;
    }
    .helper-text {
      margin-top: 8px;
      color: $neutral-500;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .parent-select {
      flex-direction: column;
      row-gap: 20px;
    }
  }
}
</style>
