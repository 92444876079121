<template>
  <div class="library-block item-block">
    <div class="header">
      <div class="heading-6">Photos & Files</div>
      <mcr-button class="white small" v-if="isWriteAllowed" @click="addFiles"
        ><plus-icon :size="20" /><span>Upload</span></mcr-button
      >
      <input type="file" multiple class="files-input" :ref="filesInputRef" @change="filesSelectedHandler" />
    </div>
    <div class="content" v-if="familyTreePersonFilesLoadingState">
      <div class="files-container image-preview-gallery">
        <file-preview v-for="i in [1, 2, 3, 4]" :key="i" class="photo-loading"></file-preview>
      </div>
    </div>
    <div class="content" v-else-if="!familyTreePersonFilesMetaState.total_count">
      <div class="empty-state desktop-only" v-if="isWriteAllowed">
        <upload-icon :size="56" class="upload-icon"></upload-icon>
        <div class="label">Drag photos, videos, and files here to upload</div>
      </div>
      <div class="empty-state mobile-only" @click="addFiles" v-if="isWriteAllowed">
        <upload-icon :size="56" class="upload-icon"></upload-icon>
        <div class="label">Tap to upload photos, videos and files</div>
      </div>
    </div>
    <div v-else class="content">
      <div class="files-container image-preview-gallery">
        <file-preview
          v-for="asset in familyTreePersonFilesState"
          :key="asset._id || asset.object_id"
          :src="getPreviewSrc(asset)"
          :filename="asset.filename"
          :has-error="asset.error"
          :is-uploading="asset.uploading"
          :upload-progress="asset.progress"
          @click.native="showGallery(asset)"
        ></file-preview>
      </div>

      <div
        v-if="familyTreePersonFilesMetaState.next || familyTreePersonFilesMetaState.previous"
        class="pagination-container"
      >
        <base-pagination
          :meta="familyTreePersonFilesMetaState"
          :show-jump-to="false"
          @onSwitchPage="onSwitchPage"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import mcrButton from '@common/elements/buttons/mcrButton';
import PlusIcon from 'vue-material-design-icons/PlusCircleOutline';
import UploadIcon from 'vue-material-design-icons/Upload';
import {mapGetters} from 'vuex';

import consts from '@/base/utils/consts';

import filePreview from '@/components/common/filePreview';
import {getNonImagePreviewSrcByFilename} from '@/components/common/filePreviewHelper';
import ExtractTextInterstitial from '@/components/common/tree/ExtractTextInterstitial.vue';

export default {
  created() {
    this.fetchFiles().then(() => {
      this.$emit('initialized');
    });
  },
  data() {
    return {
      filesInputRef: 'files-input',
      maxFileSizeMB: consts.maxFileSizeMB,
      maxFileSize: consts.maxFileSizeMB * 1024 * 1024,
    };
  },
  computed: {
    ...mapGetters([
      'familyTreePersonState',
      'familyTreePersonPrimaryFullnameEnState',
      'familyTreePersonPrimaryFullnameCnState',
      'familyTreePersonFilesState',
      'familyTreePersonFilesMetaState',
      'familyTreePersonFilesLoadingState',
    ]),
    personId() {
      return this.$route.params.personId;
    },
    familyTreeId() {
      return this.$route.params.id;
    },
    isWriteAllowed() {
      return this.familyTreePersonState.is_write_allowed;
    },
    galleryOverlayItems() {
      return this.familyTreePersonFilesState.map(p => {
        return {
          ...p,
          src: getNonImagePreviewSrcByFilename(this, p.filename) || p.attachment,
          ...this.fileMetadata,
        };
      });
    },
    fileMetadata() {
      return {
        editable: this.isWriteAllowed,
        personId: this.personId,
        personNameEn: this.familyTreePersonPrimaryFullnameEnState,
        personNameCn: this.familyTreePersonPrimaryFullnameCnState,
      };
    },
  },
  methods: {
    fetchFiles(page) {
      const treeId = this.$route.params.id;
      const personId = this.personId;
      return this.$store.dispatch('fetchFamilyTreePersonFilesAction', {treeId, personId, page: page || 1});
    },
    onSwitchPage(page) {
      this.fetchFiles(page);
    },
    getPreviewSrc(asset) {
      if (asset.uploadingPreviewSrc) {
        return asset.uploadingPreviewSrc;
      }
      return asset.attachment;
    },
    initializeGallery() {
      this.$store.commit('setGalleryItemsState', this.galleryOverlayItems);
    },
    showGallery(asset) {
      if (!asset.uploading && !asset.error) {
        this.initializeGallery();
        this.$store.dispatch('displayGalleryAction', asset._id);
      }
    },
    addFiles() {
      this.$refs[this.filesInputRef].click();
    },
    filesSelectedHandler(event) {
      this.filesSelected(event.target.files);
    },
    filesSelected(files) {
      for (let file of files) {
        if (file.size > this.maxFileSize) {
          return this.handleFilesSelectError(`File max size: ${this.maxFileSizeMB}MB`);
        }
        this.$store
          .dispatch('uploadFamilyTreePersonFileAction', {
            familyTreeId: this.familyTreeId,
            personId: this.personId,
            file,
          })
          .then(res => {
            if (res.show_text_extract_tutorial) {
              this.$modal.show(
                ExtractTextInterstitial,
                {},
                {
                  clickToClose: true,
                  scrollable: true,
                  height: 'auto',
                  classes: 'clear_modal white_modal',
                  class: 'mobile_bottom',
                }
              );
            }
          });
      }
    },
    clearInputFiles() {
      this.$refs[this.filesInputRef].value = '';
    },
    handleFilesSelectError(errorText) {
      this.clearInputFiles();
      this.$toasted.error(errorText);
    },
  },
  components: {filePreview, PlusIcon, mcrButton, BasePagination, UploadIcon},
  name: 'LibraryBlock',
};
</script>

<style lang="scss" scoped>
.library-block {
  .files-input {
    display: none;
  }
  .content {
    position: relative;
    .photo-loading {
      &::v-deep .file-preview-content {
        display: none;
      }
    }

    .empty-state {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      align-items: center;
      justify-content: center;
      padding: 24px;
      border: 1px dashed $neutral-300;

      .label {
        color: $neutral-600;
      }

      .upload-icon {
        color: $neutral-400;
      }

      &.mobile-only {
        display: none;
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        &.desktop-only {
          display: none;
        }
        &.mobile-only {
          display: flex;
        }
      }
    }
  }
}
</style>
