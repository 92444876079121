<template>
  <div class="quick-add-relative-modal" v-touch:swipe.bottom="closeModal">
    <div class="heading quick-actions-heading">
      <div>Add relative of {{ personName }}</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <div v-if="relativesLoading" class="sub-action loading-message">Fetching relatives...</div>
    <template v-else>
      <div class="quick-action" :class="{disabled: !canAddParents}" @click="clickAddParent">
        <div>Parent</div>
      </div>
      <div class="quick-action" @click="clickAddSpouse">
        <div>Spouse</div>
      </div>
      <div class="quick-action" @click="clickAddSibling">
        <div>Sibling</div>
      </div>
      <div class="quick-action" @click="clickAddChild">
        <div>Child</div>
      </div>
    </template>
  </div>
</template>

<script>
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import CloseIcon from 'vue-material-design-icons/Close';

export default {
  props: {
    personName: String,
    personId: Number,
    addParentHandler: Function,
    addSpouseHandler: Function,
    addSiblingHandler: Function,
    addChildHandler: Function,
  },
  computed: {
    relativesLoading() {
      return this.$store.getters.familyTreePersonRelativesLoadingState;
    },
    personParents() {
      return this.$store.getters.getFamilyTreePersonRelativesState(this.personId).parents || [];
    },
    canAddParents() {
      return this.personParents.length !== 2;
    },
  },
  methods: {
    clickAddParent() {
      if (!this.canAddParents) {
        return this.$toasted.error(`Could not add parent because ${this.personName} already has two parents`);
      }
      this.closeModal();
      this.trackClick('Parent');
      this.addParentHandler();
    },
    clickAddSpouse() {
      this.closeModal();
      this.trackClick('Spouse');
      this.addSpouseHandler();
    },
    clickAddSibling() {
      this.closeModal();
      this.trackClick('Sibling');
      this.addSiblingHandler();
    },
    clickAddChild() {
      this.closeModal();
      this.trackClick('Child');
      this.addChildHandler();
    },
    closeModal() {
      this.$emit('close');
    },
    trackClick(label) {
      AnalyticsAmplitudeHandler.trackTreeQuickMenuActionClickEvent(label, null);
    },
  },
  components: {CloseIcon},
  name: 'QuickAddRelativeModal',
};
</script>

<style scoped lang="scss">
.quick-add-relative-modal {
  .quick-action {
    font-weight: 500;
    justify-content: center;
  }
}
</style>
