<template>
  <div class="import-gedcom-page">
    <div class="readable_content">
      <router-link class="breadcrumb-link" :to="{name: 'familytree-manage-all'}"
        ><span v-if="isOnboarding">‹ Back to Onboarding</span><span v-else>‹ Back to Manage Trees</span></router-link
      >

      <div class="header">
        <span class="caption">Import Family Tree with GEDCOM File</span>
        <span class="beta-badge">Beta</span>
        <p>
          If you have an existing family tree made with another genealogy platform, you can import it here. Check your
          family tree building platform for an export function to get a GEDCOM file.
        </p>
        <p>
          <router-link target="_blank" :to="{name: 'wiki-article', params: {slug: 'gedcom-export-guide'}}"
            >How to export GEDCOM file from 3rd Party Platforms?
          </router-link>
        </p>
      </div>
      <template v-if="!isUploadingProcess">
        <input
          type="file"
          :accept="gedcomExtensionsAccept"
          :ref="fileInputRef"
          class="files-input"
          @change="fileInputChange"
        />
        <Transition name="slider">
          <div v-if="!gedcomFile" class="section">
            <mcr-button @click="selectFile">Choose file</mcr-button>
          </div>
        </Transition>
        <Transition name="slider">
          <div v-if="gedcomFile" class="section">
            <mcr-button :class="uploadButtonStyle" @click="startImport">Upload</mcr-button>
            <div>
              <span class="import-file-name">{{ gedcomFile.name }}</span>
              <div>or <a @click="selectFile">select another file</a></div>
            </div>
          </div>
        </Transition>
      </template>
      <div class="section result-section">
        <Transition name="slider">
          <div class="progress" v-if="isProgressing">
            <span v-if="progressText" class="subtitle"
              >{{ progressText }}... <span v-if="percent">{{ percent }}%</span></span
            >
            <span v-if="gedcomFile">{{ gedcomFile.name }}</span>

            <progress class="progress-bar" v-if="percent !== null" max="100" :value="percent"></progress>
            <mcr-loading-indicator :loading="isProgressing"></mcr-loading-indicator>

            <span v-if="isProgressing" class="please-wait"
              >Please wait while we process your GEDCOM file. This may take up to a few minutes.</span
            >
          </div>
        </Transition>
        <Transition name="slider">
          <div class="progress" v-if="treeId">
            <span class="subtitle">Completed!</span>
            <span v-if="gedcomFile">{{ gedcomFile.name }}</span>

            <span class="stats-info" v-if="personImported"
              >{{ personImported }} persons have been imported to your family tree.</span
            >
            <mcr-button-router-link :to="getToFamilyTreeRoute()" class="view-tree-link">
              <tree-icon />
              <span class="label avenir-font-offset-1">View tree</span>
            </mcr-button-router-link>
            <div class="errors" v-if="hasImagesIssues">
              <span class="warning subtitle">Issues detected!</span> {{ freshExportSuggestionText }}
            </div>
            <div class="errors" v-if="importErrors && importErrors.length">
              <span class="warning subtitle">Warning!</span> The following issues occurred while importing ({{
                importErrors.length
              }}):
              <ul>
                <li v-for="error in importErrors">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </Transition>
        <Transition name="slider">
          <div v-if="importFailed">
            <span class="subtitle">Import failed</span>
            <p class="failed-message">
              <span class="subtitle">We failed to import your GEDCOM file.</span> Please email us at
              <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a>, and our team can help you import
              your GEDCOM file manually.

              <span v-if="failMessage">
                <br /><br />

                {{ failMessage }}
              </span>
            </p>

            <a @click="reset">‹ Try Another File</a>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import mcrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import network from '@common/network/network';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import TreeIcon from 'vue-material-design-icons/Sitemap';

import consts from '@/base/utils/consts';

export default {
  name: 'ImportGedcomPage',
  data() {
    const maxFileSizeMB = 50;
    return {
      maxFileSizeMB: maxFileSizeMB,
      maxFileSize: maxFileSizeMB * 1024 * 1024,
      fileInputRef: 'file-input-ref',
      allowedFileExtensions: consts.gedcomExtensions,

      gedcomFile: null,
      progressText: null,
      isProgressing: false,
      statusTrackingKey: this.$route.query.importKey,
      treeId: null,
      stats: null,
      importErrors: [],
      importFailed: false,
      failMessage: null,
      hasImagesIssues: false,
      percent: null,
      importStart: null,
      freshExportSuggestionText:
        'We were unable to download files for your tree, the links might be stale. Please re-export your GEDCOM file from your family tree building platform and try again.',
    };
  },
  mounted() {
    this.statusTrackingKey = this.$route.query.importKey;
    if (this.statusTrackingKey) {
      this.checkStatusAPI();
    }
  },
  computed: {
    uploadButtonStyle() {
      return {
        disabled: !this.gedcomFile,
      };
    },
    isUploadingProcess() {
      return this.statusTrackingKey || this.progressText !== null;
    },
    personImported() {
      if (this.stats && this.stats.persons) {
        return this.stats.persons;
      }
    },
    gedcomExtensionsAccept() {
      return this.allowedFileExtensions.map(item => `.${item}`).join(',');
    },
    isOnboarding() {
      return this.$route.query.onboarding;
    },
    replaceTreeId() {
      return this.isOnboarding ? this.$store.getters.userFamilyTreeIdState : null;
    },
  },
  methods: {
    reset() {
      const newQuery = this.isOnboarding ? {onboarding: true} : {};
      this.$router.replace({query: newQuery});
      this.$router.go(this.$router.currentRoute);
    },
    getToFamilyTreeRoute() {
      return {name: 'familytree-details', params: {id: this.treeId}};
    },
    startImport() {
      this.isProgressing = true;
      this.percent = null;
      this.progressText = 'Uploading';
      AnalyticsAmplitudeHandler.trackGedcomUploadClickedEvent();
      this.importStart = new Date().getTime();

      // can't send "null" value to multipart form data, it is converted to string.
      const treeId = this.replaceTreeId ? {tree_id: this.replaceTreeId} : {};
      network.familyTree
        .importGedcom({file: this.gedcomFile, ...treeId}, progressRes => {
          this.percent = ((progressRes.loaded / progressRes.total) * 100).toFixed(2);
        })
        .then(response => {
          this.statusTrackingKey = response.importKey;
          this.$router.push({query: {...this.$route.query, importKey: this.statusTrackingKey}});
          this.$store.commit('advanceUserOnboardingState', {isOnboarded: true, onboardingStep: null});
          this.checkStatusAPI();
        })
        .catch(error => {
          this.importFailed = true;
          this.isProgressing = false;
          this.progressText = error;
          AnalyticsAmplitudeHandler.trackGedcomImportFailedEvent('import request');
        });
    },
    checkStatusAPI() {
      if (this.statusTrackingKey) {
        this.isProgressing = true;
        network.familyTree
          .importStatus({importKey: this.statusTrackingKey})
          .then(response => {
            this.percent = response.percent;
            if (this.percent) {
              this.percent = Math.round(this.percent);
            }
            if (response.status === 'done') {
              this.progressText = 'Import success';
              this.treeId = response.tree_id;
              this.isProgressing = false;
              this.importErrors = response.errors;
              this.stats = response.stats;
              this.hasImagesIssues = response.has_images_issues;
              this.$store.dispatch('cleanupTreeStateAction');
              if (this.treeId) {
                AnalyticsAmplitudeHandler.trackGedcomImportSuccessEvent(
                  this.stats ? this.stats.persons || 0 : 0,
                  this.stats ? this.stats.assets || 0 : 0,
                  response.errors ? response.errors.length : 0,
                  this.importStart ? (new Date().getTime() - this.importStart) / 1000 : -1
                );
              } else {
                this.importFailed = true;
                AnalyticsAmplitudeHandler.trackGedcomImportFailedEvent('no tree id');
              }
            } else if (response.status === 'error') {
              this.progressText = 'Import failed';
              this.importFailed = true;
              this.failMessage = response.message;
              this.isProgressing = false;

              this.importErrors = response.errors;
              AnalyticsAmplitudeHandler.trackGedcomImportFailedEvent('file data issues');
            } else {
              this.progressText = response.status;
              this.isProgressing = true;

              setTimeout(() => {
                this.importErrors = response.errors;
                this.checkStatusAPI();
              }, 1000);
            }
          })
          .catch(error => {
            this.isProgressing = false;
            this.progressText = error;
            this.importErrors = error.data;
            this.importFailed = true;
            AnalyticsAmplitudeHandler.trackGedcomImportFailedEvent('status request');
          });
      }
    },
    selectFile() {
      this.$refs[this.fileInputRef].click();
    },
    fileInputChange(event) {
      this.filesSelected(event.target.files);
    },
    filesSelected(files) {
      if (!files.length) {
        return;
      }
      let file = files[0];
      const fileExtension = file.name.split('.').pop();
      if (!this.allowedFileExtensions.includes(fileExtension.toLowerCase())) {
        return this.handleFilesSelectError(`Allowed file extensions: ${this.allowedFileExtensions}`);
      }
      if (file.size > this.maxFileSize) {
        return this.handleFilesSelectError(`File max size: ${this.maxFileSizeMB}MB`);
      }
      this.gedcomFile = file;
      AnalyticsAmplitudeHandler.trackGedcomFileSelectedEvent();
    },
    handleFilesSelectError(errorText) {
      this.$toasted.error(errorText);
      this.$refs[this.fileInputRef].value = null;
      this.gedcomFile = null;
    },
  },
  components: {
    McrButton,
    mcrButtonRouterLink,
    TreeIcon,
  },
};
</script>

<style lang="scss" scoped>
.slider-enter-active {
  transition: all 0.2s ease;
  transition-delay: 0.2s;
}

.slider-leave-active {
  transition: all 0.2s ease;
}

.slider-enter {
  transform: translateX(100px);

  opacity: 0;
}

.slider-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}

.beta-badge {
  color: white;
  background-color: $mcr-red;
  padding: 2px 6px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: normal;
  margin-left: 5px;
  bottom: 15px;
  position: relative;
}

.breadcrumb-link {
  display: inline-flex;
  margin-right: 10px;

  .material-design-icon {
    margin-right: 5px;
  }
}

.section {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .mcr-button {
    width: 150px;
    margin-right: 10px;
  }

  .subtitle {
    font-weight: bold;
  }

  .material-design-icon {
    margin-right: 10px;
  }
}

.files-input {
  display: None;
}

.progress {
  display: flex;
  flex-direction: column;
  width: 100%;

  .please-wait {
    text-align: center;
  }
}

.errors {
  margin-top: 40px;

  ul {
    max-height: 500px;
    overflow-y: auto;
  }
}

.stats-info {
  margin-top: 40px;
}

.view-tree-link {
  margin: 10px 0;
}

.progress-bar {
  width: 100%;
  height: 12px;
  margin-top: 20px;
}

progress::-webkit-progress-bar {
  background-color: $border-color;
  width: 100%;
}

progress {
  background-color: $border-color;
}

progress::-webkit-progress-value {
  background-color: $mcr-red !important;
}

progress::-moz-progress-bar {
  background-color: $mcr-red !important;
}

progress {
  color: $mcr-red;
}

.warning {
  color: #ad7200;
}

.failed-message {
  color: #b80c15;
  border-bottom: 1px solid $divider-line-color;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.header {
  margin-top: 20px;

  .caption {
    font-size: 28px;
    font-weight: bold;
  }

  p {
    color: $supplemental-text-color;
  }

  border-bottom: 1px solid $divider-line-color;
  margin-bottom: 20px;
}
</style>
